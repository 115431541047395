import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from "firebase/auth";

// const STRIPE_URL="https://billing.stripe.com/p/login/test_5kAcPqd7aa4S3YI8ww"
const STRIPE_URL = "https://billing.stripe.com/p/login/bIYg0OfLK34l8jS4gg"

const Settings = () => {
    const navigate = useNavigate();
    const auth = getAuth();
    const user = auth.currentUser;
    const email = user?.providerData.find(profile => profile.email)?.email || null;
    
    const stripeLoginUrl = `${STRIPE_URL}?prefilled_email=${email}`;

    useEffect(() => {
        window.location.href = stripeLoginUrl;
    }, [stripeLoginUrl]);

    return (
        <div style={{ textAlign: 'center', marginTop: '20%' }}>
            <p>Redirecting to billing...</p>
            {/* You can replace this text with a loading spinner component if you have one */}
        </div>
    );
};

export default Settings;
