import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  theme,
  Heading,
  Stack,
  SimpleGrid,
  Container,
} from "@chakra-ui/react"
import { SmallWithLogoLeft } from '../components/SmallWithLogoLeft'
import { SplitWithImage } from "../components/SplitWithImage"
import Header from "../components/Header"
import { TieredPricing } from "../components/Pricing"
import { HeroScreen } from "../components/Hero"
import { FaPersonWalking, FaPeopleArrows } from "react-icons/fa6";
import { GiWeightLiftingUp } from "react-icons/gi";
import { useEffect, useState } from "react"
import useAuth from "../hooks/useAuth"
import { SampleCode } from "../components/SampleCode"

export const LandingPage = () => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    setUserLoggedIn(isAuthenticated());
  }, []);

  return <ChakraProvider theme={theme}>
    <Box textAlign="center" fontSize="xl" bg="#F3F3F3">
      <Header />
      <HeroScreen
        userLoggedIn={userLoggedIn}
      />
      <Heading id="features">Powerful Features, Unlimited Possibilities</Heading>
      {/* <SplitWithImage 
          icon={BsFillGrid1X2Fill}
          title="Object Recognition"
          text="Harness powerful object recognition to detect and classify various items in real-time, enhancing security systems, retail analytics, and automated inventory management."
          src="https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
        /> */}
      <SplitWithImage
        icon={FaPersonWalking}
        title="Human Pose Estimation"
        text="Utilize precise human pose estimation to analyze body movements for applications in sports analytics, physical therapy, and interactive gaming."
        src="https://d3l14jro8mrgku.cloudfront.net/dotmoovs-business/coverr-surfing-2-9504_out_1.mp4"
      />
      <SplitWithImage
        icon={FaPeopleArrows}
        title="Multi-Object Tracking"
        text="Track multiple objects simultaneously even in dynamic, unpredictable environments. Ideal for surveillance, traffic management, and live sports analysis."
        src="https://d3l14jro8mrgku.cloudfront.net/dotmoovs-business/Ground-Zero_out.mp4"
      />
      <SplitWithImage
        icon={GiWeightLiftingUp}
        title="Fitness Exercise Classification"
        text="Automate the classification of fitness exercises to provide real-time feedback on performance and form, perfect for fitness apps and virtual training platforms."
        src="https://d3l14jro8mrgku.cloudfront.net/dotmoovs-business/coverr-fitness-2-9504_out.mp4"
        comingSoon
      />
      <Stack
        align="center"
        justify="center"
        direction="column"
      >
        <Heading id="how">How It Works</Heading>
        <Container maxW={'6xl'} py={12} alignItems="center">
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10}>
            <SampleCode />
            <Box
              sx={{
                borderRadius: "1%",
                height: "1xl"
              }}
              autoPlay
              loop
              muted
              as="video"
              src="https://d3l14jro8mrgku.cloudfront.net/dotmoovs-business/trimmed_pose.mp4"
              mx="auto"
              textAlign="center"
              playsInline
            />
          </SimpleGrid>
        </Container>
      </Stack>
      <VStack spacing={20} />
      <TieredPricing />
      <VStack spacing={20} />
      <SmallWithLogoLeft />
    </Box>
  </ChakraProvider>
}
