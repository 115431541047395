import {
    ChakraProvider,
    Box,
    Text,
    VStack,
    theme,
    Heading,
    Stack,
} from "@chakra-ui/react"
import {
    List,
    ListItem,
    ListIcon,
    OrderedList,
    UnorderedList,
} from '@chakra-ui/react'
import { MdCheckCircle } from "react-icons/md";

function GetStartedSteps() {
    return <List spacing={3}>
        <ListItem>
            <ListIcon as={MdCheckCircle} color='green.500' />
            <Text as="b">
                Explore Our Guides:{" "}
            </Text>
            Dive into our comprehensive guides page to discover everything you need to know about integrating and utilizing our SDK. From installation instructions to advanced techniques, our guides are here to help you every step of the way.
        </ListItem>
        <ListItem>
            <ListIcon as={MdCheckCircle} color='green.500' />
            <Text as="b">
                Download the SDK:{" "}
            </Text>
            Ready to start building? Head over to the downloads section to grab the latest version of our SDK. It's quick and easy to get up and running on your preferred platform.
        </ListItem>
        <ListItem>
            <ListIcon as={MdCheckCircle} color='green.500' />
            <Text as="b">
                Stay Updated:{" "}
            </Text>
            Don't miss out on the latest news, updates, and announcements. Be sure to follow us on social media and subscribe to our newsletter to stay in the loop.
        </ListItem>
    </List>
}

export default function HomePage() {
    // get stripe page
    return (
        <ChakraProvider theme={theme}>
            <Box textAlign="center" fontSize="xl" bg="#F3F3F3">
                <VStack spacing={8}>
                    <Heading>
                        Welcome to Dotmoovs Business!
                    </Heading>
                    <Text>
                        We're thrilled to have you on board and excited for the incredible possibilities that lie ahead with our human pose estimation technology. Whether you're a seasoned developer or just starting out, you're in the right place to unlock the power of motion analysis.
                    </Text>
                    <Text>
                        Here are a few quick steps to get you started:
                    </Text>
                    <GetStartedSteps />

                    <Text>
                        Once again, welcome to the Dotmoovs Business family! We can't wait to see what you create.
                    </Text>

                    <Text>
                        Happy coding!
                    </Text>
                    <Text>
                        Dotmoovs Business Team
                    </Text>
                </VStack>
            </Box>
        </ChakraProvider >
    );
}