import {
    Box,
    Text,
    Heading,
    Center,
    Button,
} from "@chakra-ui/react"

import { useEffect, useState } from "react";
import { firebase_auth, forwardStripe } from "../auth/JWTAuthContext";
import useAuth from "../hooks/useAuth";
import { getAuth } from "firebase/auth";

interface ApiKeyScreenProps {
    apiKey: string;
}

const ApiKeyScreen = ({ apiKey }: ApiKeyScreenProps) => {
    console.log(process.env.STRIPE_URL_PROFESSIONAL)
    return (
        <Box p={8}>
            <Text>
                Do not share your API key with others, or expose it in the browser or other client-side code.
            </Text>
            <Center>
                <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden" boxShadow="md" p={6}>
                    <Heading as="h1" size="lg" mb={4}>
                        Your API Key
                    </Heading>
                    <Text fontSize="xl" mb={4} color="gray.600">
                        {apiKey}
                    </Text>
                    <Button onClick={() => navigator.clipboard.writeText(apiKey)} colorScheme="teal" size="sm">
                        Copy API Key
                    </Button>
                </Box>
            </Center>
        </Box>
    );
};


interface NoKeyFoundProps {
    email: string | null;
}

function NoKeyFound({ email }: NoKeyFoundProps) {
    return (
        <Box p={8}>
            <Text>
                You do not have an API key yet. Please upgrade your subscription to get an API key.
            </Text>
            {
                email && (
                    <>
                        <Button w="full" borderColor="#002BFF"
                            color="#002BFF"
                            variant='solid'
                            onClick={() => {
                                forwardStripe(email, "basic");
                            }
                            }
                        >
                            Upgrade to Basic
                        </Button>
                        <Button w="full" borderColor="#002BFF"
                            color="#002BFF"
                            variant='solid'
                            onClick={() => {
                                forwardStripe(email, "professional");
                            }
                            }
                        >
                            Upgrade to Professional
                        </Button>
                    </>
                )
            }
            {
                !email && (
                    <Button w="full" borderColor="#002BFF"
                        color="#002BFF"
                        variant='solid'
                        onClick={() => {
                            window.location.href = '/settings';
                        }
                        }
                    >
                        Upgrade Now
                    </Button>
                )
            }
        </Box>
    );

}


export default function APIKeyScreen() {
    const [apiKey, setApiKey] = useState<string | null>(null);


    const auth = getAuth();
    const user = auth.currentUser;
    const email = user?.providerData.find(profile => profile.email)?.email || null;



    useEffect(() => {
        // Function to fetch the API key
        const fetchApiKey = async () => {
            try {
                const response = await fetch("https://prod-dotmoovs-business-api-picjklnwjq-ew.a.run.app/info", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email: email }),
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log("Success:", data);
                    setApiKey(data);
                } else {
                    const errorData = await response.json();
                    console.error("Error:", errorData.error);
                }
            } catch (error) {
                console.error("Error:", error);
            }
        };
        fetchApiKey();
    }, [email]);

    return apiKey ? <ApiKeyScreen
        apiKey={apiKey}
    /> : <NoKeyFound
        email={email}
    />;
}
