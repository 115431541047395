import { Button, Link as ChakraLink } from "@chakra-ui/react";
import { ChangeEvent, useEffect, useRef } from "react";
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';

import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import { OrDivider } from "../components/OrDivider";
import { SocialButtonGroup } from "../components/SocialButtonGroup";
import useAuth from "../hooks/useAuth";

interface UserProfile {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  formName: string;
}

export function SignUp() {
  const { socialLogin, manualSignUp, logout, isAuthenticated } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const previousPage = useRef<string>("home")
  const profile = useRef<UserProfile>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    formName: "",
  });
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const formName = params.get('form');
  if (formName) {
    profile.current.formName = formName;
  }


  // Function to update the last name in the profile ref
  const setLastName = (event: ChangeEvent<HTMLInputElement>) => {
    profile.current.lastName = event.target.value;
  };

  const setFirstName = (event: ChangeEvent<HTMLInputElement>) => {
    profile.current.firstName = event.target.value;
  }

  const setEmail = (event: ChangeEvent<HTMLInputElement>) => {
    profile.current.email = event.target.value;
  }

  const setPassword = (event: ChangeEvent<HTMLInputElement>) => {
    profile.current.password = event.target.value;
  }

  useEffect(() => {
    // check if the user is already logged in
    if (isAuthenticated()) {
      console.log("User is already logged in");
      window.location.href = "/home";
    } else {
      console.log("User is not logged in");
    }
  }, []);

  const forwardHome = () => {
    window.location.href = "/home";
  }

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg="#F3F3F3">
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'} textAlign={'center'}>
            Create your account
          </Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            Welcome! Please fill in the details to get started.
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <SocialButtonGroup
              githubLogin={() => socialLogin({ type: "github", formName: formName }, null)}
              googleLogin={() => socialLogin({ type: "google", formName: formName }, null)}
            />
            <OrDivider />
            <HStack>
              <Box>
                <FormControl id="firstName" isRequired>
                  <FormLabel>First Name</FormLabel>
                  <Input type="text" onChange={setFirstName} />
                </FormControl>
              </Box>
              <Box>
                <FormControl id="lastName">
                  <FormLabel>Last Name</FormLabel>
                  <Input type="text" onChange={setLastName} />
                </FormControl>
              </Box>
            </HStack>
            <FormControl id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input type="email" onChange={setEmail} />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input type={showPassword ? 'text' : 'password'} onChange={setPassword} />
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }>
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Stack spacing={10} pt={2}>
              <Button
                loadingText="Submitting"
                size="lg"
                bg={'#002BFF'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
                onClick={() => {
                  manualSignUp(profile.current, forwardHome);
                }}
              >
                Sign up
              </Button>
            </Stack>
            <Stack pt={6}>
              <Text align={'center'}>
                Already a user? <ChakraLink color={'#002BFF'} as={ReactRouterLink} to="/signin">Login</ChakraLink>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default SignUp;
