'use client'

import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react'
import { FaInstagram, FaTwitter, FaYoutube, FaTiktok, FaFacebook } from 'react-icons/fa'
import { ReactNode } from 'react'

export const Logo = (props: any) => {
  return (
    <svg width="174" height="32" viewBox="0 0 174 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.602 14.4377L16.5499 14.4076L12.8998 12.3002C12.8839 12.2907 12.8681 12.2814 12.8522 12.272C12.582 12.1186 12.2692 12.0312 11.9363 12.0312C10.91 12.0312 10.0781 12.8632 10.0781 13.8893V18.1653C10.0781 19.1913 10.9099 20.0234 11.9363 20.0234C12.2568 20.0234 12.5581 19.9416 12.8214 19.7987C12.8303 19.794 12.8381 19.7899 12.8466 19.7849C12.8647 19.7748 12.8825 19.7649 12.8996 19.7543L16.5553 17.643L16.602 17.6161C17.1382 17.2903 17.4964 16.7006 17.4964 16.027C17.4964 15.3536 17.1382 14.7642 16.602 14.4377Z" fill="#002BFF" />
      <path d="M28.3836 11.0195C28.1612 10.5603 27.8022 10.1818 27.3553 9.93742L11.3923 0.721231C11.3434 0.691743 11.2947 0.662736 11.2433 0.635813C10.8965 0.445264 10.5006 0.337891 10.0756 0.337891C9.66296 0.337891 9.27449 0.440296 8.9325 0.621229C8.88602 0.645588 8.8373 0.675076 8.79323 0.702L5.43949 2.63873L2.09776 4.56585C2.0516 4.59053 2.00257 4.6197 1.95865 4.64662C1.29438 5.05705 0.842288 5.77501 0.800781 6.60082V25.4539C0.842288 26.2768 1.29438 26.9951 1.95865 27.4079C2.00257 27.4323 2.0516 27.4615 2.09776 27.4884L5.43965 29.4134L8.79339 31.3503C8.83746 31.3798 8.88618 31.4064 8.93266 31.4311C9.27465 31.6117 9.66312 31.7144 10.0758 31.7144C10.5008 31.7144 10.8966 31.6067 11.2434 31.4189C11.2947 31.3894 11.3436 31.3625 11.3925 31.3309L27.3554 22.117C27.8024 21.8729 28.1614 21.4916 28.3838 21.0349C28.5402 20.715 28.628 20.3509 28.628 19.97V12.0822C28.628 11.7009 28.5401 11.3397 28.3836 11.0195ZM27.9245 16.4375H27.9221L26.0216 17.534L24.153 18.6142L9.90719 26.8384L8.04819 27.9086L6.12331 29.0198C6.05745 29.0567 5.98405 29.0786 5.90328 29.0786C5.64686 29.0786 5.43933 28.8708 5.43933 28.612V3.44003C5.43933 3.18329 5.64686 2.97592 5.90328 2.97592C5.98405 2.97592 6.05745 2.99787 6.12331 3.03233L8.04819 4.14357L9.90719 5.21586L24.153 13.4404L26.0216 14.5199L27.9221 15.617H27.9245C28.0686 15.6975 28.1663 15.8515 28.1663 16.0271C28.1663 16.2031 28.0686 16.3568 27.9245 16.4375Z" fill="#002BFF" />
      <path d="M53.068 3.28711H51.8367C51.297 3.28711 50.8596 3.72446 50.8596 4.26437V7.58638C50.8596 7.85017 50.6446 8.06284 50.3808 8.06284H45.3001C44.8311 8.06284 44.4085 8.2287 44.0714 8.49762C44.0202 8.53929 43.9714 8.58304 43.9249 8.62935C43.9225 8.63192 43.9225 8.63432 43.9202 8.63432L38.7294 13.8274C38.7099 13.8445 38.6903 13.8641 38.673 13.8838C38.6339 13.9251 38.5948 13.9692 38.5607 14.0134C38.2871 14.3452 38.1211 14.7753 38.1211 15.2445V19.9906C38.1211 20.4595 38.2895 20.8892 38.5607 21.2217C38.6044 21.2802 38.6535 21.3317 38.7048 21.3829L40.7394 23.4203C40.7884 23.4692 40.8373 23.513 40.8886 23.5546C41.228 23.8232 41.6481 23.9894 42.1172 23.9894H50.0487C50.5178 23.9894 50.9378 23.8232 51.2771 23.5546C51.3284 23.513 51.3774 23.4692 51.4265 23.4203L53.4611 21.3829C53.5122 21.3317 53.5613 21.2802 53.6052 21.2217C53.8762 20.8893 54.0446 20.4595 54.0446 19.9906V4.26421C54.0448 3.7243 53.6077 3.28711 53.068 3.28711ZM50.8596 20.3249C50.8596 20.5887 50.6471 20.8014 50.3808 20.8014H41.7851C41.5189 20.8014 41.3064 20.5889 41.3064 20.3249V11.7267C41.3064 11.4629 41.5189 11.2478 41.7851 11.2478H50.3808C50.6446 11.2478 50.8596 11.4629 50.8596 11.7267V20.3249Z" fill="black" />
      <path d="M71.6498 10.8302C71.6061 10.7738 71.557 10.7199 71.508 10.6686L69.4709 8.63158C69.4219 8.5827 69.373 8.53895 69.3217 8.49728C68.9823 8.22837 68.5645 8.0625 68.0956 8.0625H60.1616C59.6925 8.0625 59.2725 8.22837 58.9355 8.49728C58.8844 8.53895 58.8353 8.5827 58.7842 8.63158L56.7494 10.6686C56.6983 10.7199 56.6516 10.7738 56.6077 10.8302C56.3366 11.1622 56.168 11.5924 56.168 12.0613V19.9904C56.168 20.4594 56.3366 20.889 56.6077 21.2215C56.6515 21.2776 56.6981 21.3315 56.7494 21.3802L58.7842 23.4176C58.8353 23.4665 58.8844 23.5102 58.9355 23.5519C59.2727 23.8205 59.6927 23.9867 60.1616 23.9867H68.0956C68.5647 23.9867 68.9823 23.8205 69.3217 23.5519C69.3729 23.5102 69.4219 23.4665 69.4709 23.4176L71.508 21.3802C71.557 21.3313 71.6059 21.2775 71.6498 21.2215C71.921 20.8892 72.0894 20.4594 72.0894 19.9904V12.0613C72.0894 11.5924 71.921 11.1622 71.6498 10.8302ZM68.9041 20.3225C68.9041 20.5863 68.6916 20.7986 68.4254 20.7986H59.832C59.5659 20.7986 59.3534 20.5864 59.3534 20.3225V11.7267C59.3534 11.4629 59.5659 11.2502 59.832 11.2502H68.4254C68.6916 11.2502 68.9041 11.4627 68.9041 11.7267V20.3225Z" fill="black" />
      <path d="M83.7667 21.7759V23.0097C83.7667 23.2413 83.6834 23.4567 83.5492 23.6251C83.3686 23.8474 83.0951 23.9867 82.7896 23.9867H78.2068C77.7379 23.9867 77.3179 23.8205 76.9785 23.5519C76.9272 23.5102 76.8783 23.4665 76.8293 23.4176L74.7944 21.3802C74.7433 21.3293 74.6943 21.2775 74.6505 21.2216C74.3794 20.8869 74.2109 20.4594 74.2109 19.9905V4.26437C74.2109 3.72446 74.6483 3.28711 75.188 3.28711H76.4191C76.9591 3.28711 77.3964 3.72446 77.3964 4.26437V7.58638C77.3964 7.85017 77.6136 8.06284 77.8751 8.06284H79.7731C80.2764 8.06284 80.7306 8.25098 81.075 8.55611L82.8972 10.381C82.9217 10.4029 82.9438 10.4247 82.9632 10.4467C83.034 10.5347 83.0755 10.6424 83.0755 10.7594C83.0755 11.028 82.8556 11.248 82.5871 11.248H77.8751C77.6136 11.248 77.3964 11.4605 77.3964 11.7268V20.3227C77.3964 20.5865 77.6089 20.7988 77.8751 20.7988H82.7898C83.0975 20.7988 83.3712 20.9406 83.5494 21.1607C83.6835 21.3293 83.7667 21.5414 83.7667 21.7759Z" fill="black" />
      <path d="M104.997 12.0611V23.0094C104.997 23.549 104.56 23.9863 104.02 23.9863H102.794C102.254 23.9863 101.817 23.549 101.817 23.0094V11.7265C101.817 11.4602 101.6 11.2477 101.338 11.2477H97.5155C97.2517 11.2477 97.0368 11.4602 97.0368 11.7265V23.0094C97.0368 23.549 96.5995 23.9863 96.0597 23.9863H94.8312C94.2914 23.9863 93.8541 23.549 93.8541 23.0094V11.7265C93.8541 11.4602 93.6388 11.2477 93.3754 11.2477H89.5525C89.2888 11.2477 89.0761 11.4602 89.0761 11.7265V23.0094C89.0761 23.549 88.6387 23.9863 88.099 23.9863H86.8677C86.328 23.9863 85.8906 23.549 85.8906 23.0094V9.03928C85.8906 8.80482 85.9736 8.58975 86.1105 8.42388C86.1522 8.37004 86.2009 8.32116 86.2523 8.27965C86.4208 8.14535 86.6333 8.0625 86.8677 8.0625H93.043C93.5461 8.0625 94.0005 8.25064 94.3449 8.55578L96.4945 10.7055C96.5507 10.7616 96.6312 10.7981 96.719 10.7981C96.895 10.7981 97.0365 10.6542 97.0365 10.4779V9.03928C97.0365 8.80482 97.1195 8.58975 97.2564 8.42388C97.298 8.37004 97.3468 8.32116 97.3982 8.27965C97.5666 8.14535 97.7791 8.0625 98.0136 8.0625H101.001C101.47 8.0625 101.89 8.22837 102.23 8.49728C102.281 8.53895 102.33 8.5827 102.379 8.63158L104.414 10.6686C104.465 10.7199 104.514 10.7714 104.558 10.8302C104.829 11.1621 104.997 11.5922 104.997 12.0611Z" fill="black" />
      <path d="M122.605 10.8302C122.561 10.7738 122.513 10.7199 122.464 10.6686L120.426 8.63158C120.378 8.5827 120.329 8.53895 120.277 8.49728C119.938 8.22837 119.518 8.0625 119.049 8.0625H111.117C110.648 8.0625 110.228 8.22837 109.889 8.49728C109.837 8.53895 109.788 8.5827 109.74 8.63158L107.705 10.6686C107.653 10.7199 107.607 10.7738 107.561 10.8302C107.29 11.1622 107.121 11.5924 107.121 12.0613V19.9904C107.121 20.4594 107.29 20.889 107.561 21.2215C107.607 21.2776 107.653 21.3315 107.705 21.3802L109.74 23.4176C109.788 23.4665 109.837 23.5102 109.889 23.5519C110.228 23.8205 110.648 23.9867 111.117 23.9867H119.049C119.518 23.9867 119.938 23.8205 120.277 23.5519C120.329 23.5102 120.378 23.4665 120.426 23.4176L122.464 21.3802C122.513 21.3313 122.561 21.2775 122.605 21.2215C122.877 20.8892 123.045 20.4594 123.045 19.9904V12.0613C123.045 11.5924 122.877 11.1622 122.605 10.8302ZM119.86 20.3225C119.86 20.5863 119.647 20.7986 119.381 20.7986H110.785C110.519 20.7986 110.306 20.5864 110.306 20.3225V11.7267C110.306 11.4629 110.519 11.2502 110.785 11.2502H119.381C119.647 11.2502 119.86 11.4627 119.86 11.7267V20.3225Z" fill="black" />
      <path d="M140.652 10.8302C140.608 10.7738 140.559 10.7199 140.508 10.6686L138.473 8.63158C138.424 8.5827 138.376 8.53895 138.324 8.49728C137.985 8.22837 137.565 8.0625 137.096 8.0625H129.164C128.695 8.0625 128.275 8.22837 127.935 8.49728C127.884 8.53895 127.835 8.5827 127.786 8.63158L125.752 10.6686C125.7 10.7199 125.652 10.7738 125.608 10.8302C125.337 11.1622 125.168 11.5924 125.168 12.0613V19.9904C125.168 20.4594 125.337 20.889 125.608 21.2215C125.652 21.2776 125.7 21.3315 125.752 21.3802L127.786 23.4176C127.835 23.4665 127.884 23.5102 127.935 23.5519C128.275 23.8205 128.695 23.9867 129.164 23.9867H137.096C137.565 23.9867 137.985 23.8205 138.324 23.5519C138.376 23.5102 138.424 23.4665 138.473 23.4176L140.508 21.3802C140.559 21.3313 140.608 21.2775 140.652 21.2215C140.923 20.8892 141.092 20.4594 141.092 19.9904V12.0613C141.092 11.5924 140.923 11.1622 140.652 10.8302ZM137.906 20.3225C137.906 20.5863 137.694 20.7986 137.428 20.7986H128.832C128.566 20.7986 128.353 20.5864 128.353 20.3225V11.7267C128.353 11.4629 128.566 11.2502 128.832 11.2502H137.428C137.694 11.2502 137.906 11.4627 137.906 11.7267V20.3225Z" fill="black" />
      <path d="M163.308 11.5672C163.308 11.6651 163.352 11.7531 163.423 11.8113C163.43 11.8163 163.435 11.8238 163.442 11.8284L171.926 18.6632C171.926 18.6632 171.931 18.6632 171.933 18.6657C172.144 18.8391 172.278 19.1055 172.278 19.401V19.9898C172.278 20.4587 172.109 20.8884 171.838 21.2209C171.794 21.277 171.745 21.3308 171.694 21.3796L169.659 23.4169C169.61 23.4658 169.562 23.5096 169.51 23.5512C169.171 23.8198 168.75 23.986 168.282 23.986H160.516C160.282 23.986 160.069 23.9027 159.901 23.7685C159.681 23.5905 159.539 23.3169 159.539 23.0092V21.7778C159.539 21.5434 159.622 21.3286 159.759 21.1621C159.8 21.1086 159.849 21.0597 159.901 21.0182C160.069 20.8839 160.282 20.801 160.516 20.801H168.189C168.367 20.801 168.509 20.6592 168.509 20.4808C168.509 20.3856 168.467 20.2977 168.396 20.2388C168.394 20.2366 162.468 15.4636 161.117 14.3791C161.11 14.3719 161.102 14.3669 161.095 14.3597C160.887 14.1814 160.756 13.915 160.756 13.6195C160.756 13.3509 160.866 13.1089 161.039 12.9305C161.041 12.928 161.041 12.928 161.044 12.9259L165.338 8.63398C165.341 8.63398 165.341 8.63142 165.343 8.62902C165.389 8.5827 165.438 8.53879 165.489 8.49728C165.827 8.22837 166.249 8.0625 166.718 8.0625H171.301C171.841 8.0625 172.278 8.49985 172.278 9.03944V10.2706C172.278 10.8105 171.841 11.2478 171.301 11.2478H163.628C163.45 11.2473 163.308 11.3912 163.308 11.5672Z" fill="black" />
      <path d="M158.158 8.06266H156.927C156.387 8.06266 155.95 8.50001 155.95 9.0396V15.9013C155.95 16.1577 155.85 16.3921 155.688 16.5607C155.676 16.5729 155.664 16.5827 155.654 16.5949L151.67 20.5791C151.543 20.7052 151.368 20.7834 151.173 20.784C150.978 20.7834 150.803 20.7052 150.676 20.5791L146.692 16.5949C146.682 16.5827 146.67 16.5729 146.658 16.5607C146.497 16.392 146.396 16.1575 146.396 15.9013V9.03944C146.396 8.49985 145.959 8.0625 145.419 8.0625H144.188C143.648 8.0625 143.211 8.49985 143.211 9.03944V16.8074C143.211 17.2763 143.38 17.7063 143.651 18.0385C143.692 18.0923 143.739 18.1412 143.785 18.1899L143.79 18.1949L149.012 23.4149L149.017 23.4198C149.064 23.4661 149.112 23.5101 149.164 23.5516C149.501 23.8202 149.924 23.9863 150.392 23.9863H151.173H151.953C152.422 23.9863 152.845 23.8202 153.182 23.5516C153.233 23.5099 153.282 23.4661 153.328 23.4198L153.333 23.4149L158.556 18.1949L158.56 18.1899C158.607 18.141 158.653 18.0923 158.695 18.0385C158.966 17.7064 159.135 17.2763 159.135 16.8074V9.03944C159.135 8.49985 158.698 8.06266 158.158 8.06266Z" fill="black" />
    </svg>
  )
}

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode
  label: string
  href: string
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  )
}

export function SmallWithLogoLeft() {
  return (
    <Box
      bg={useColorModeValue('#E5E9FF', '#E5E9FF')}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}>
        <Logo />
        <Text>© 2024 dotmoovs business. All rights reserved</Text>
        <Stack direction={'row'} spacing={6}>
          <SocialButton label={'Facebook'} href={'https://www.facebook.com/dotmoovs2.0'}>
            <FaFacebook />
          </SocialButton>
          <SocialButton label={'Instagram'} href={'https://www.instagram.com/dotmoovs/'}>
            <FaInstagram />
          </SocialButton>
          <SocialButton label={'Twitter'} href={'https://twitter.com/dotmoovs'}>
            <FaTwitter />
          </SocialButton>
          <SocialButton label={'TikTok'} href={'https://www.tiktok.com/@dotmoovs'}>
            <FaTwitter />
          </SocialButton>
          <SocialButton label={'YouTube'} href={'https://www.youtube.com/@dotmoovs'}>
            <FaYoutube />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  )
}