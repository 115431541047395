'use client'

import {
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Stack,
  Text
} from '@chakra-ui/react'
import { ReactElement } from 'react'

interface FeatureProps {
  text: string
  iconBg: string
  icon?: ReactElement
}

interface SplitWithImageProps {
  icon: any
  title: string
  text: string
  src: string
  comingSoon?: boolean
}

export function SplitWithImage({
  icon,
  title,
  text,
  src, comingSoon }: SplitWithImageProps) {
  return (
    <Container maxW={'5xl'} py={12}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Icon as={icon} color={'#002BFF'} w={10} h={10} />
          <Heading>{title}</Heading>
          <Text color={'gray.500'} fontSize={'lg'}>
            {text}
          </Text>
          {
            comingSoon && (
              <Text
                textTransform={'uppercase'}
                color={'blue.400'}
                fontWeight={600}
                fontSize={'sm'}
                bg={'blue.50'}
                p={2}
                alignSelf={'center'}
                rounded={'md'}>

                Coming Soon
              </Text>
            )
          }
        </Stack>
        <Flex>
          <Box
            sx={{
              borderRadius: "1%"
            }}
            autoPlay
            loop
            muted
            as="video"
            src={src}
            mx="auto"
            textAlign="center"
            playsInline
          />
        </Flex>
      </SimpleGrid>
    </Container>
  )
}