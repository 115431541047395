import React, { ReactNode, useEffect } from 'react';
import {
    IconButton,
    Avatar,
    Box,
    CloseButton,
    Flex,
    HStack,
    VStack,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Divider,
} from '@chakra-ui/react';
import {
    FiHome,
    FiTrendingUp,
    FiCompass,
    FiStar,
    FiSettings,
    FiMenu,
    FiBell,
    FiChevronDown,
} from 'react-icons/fi';
import { IoDocument } from "react-icons/io5";
import { FaLock } from "react-icons/fa";

import { IconType } from 'react-icons';
import { ReactText } from 'react';
import { Logo } from './SmallWithLogoLeft';

import useAuth from '../hooks/useAuth';
import { firebase_auth } from '../auth/JWTAuthContext';



interface LinkItemProps {
    name: string;
    icon: IconType;
    path: string;
    withDivider?: boolean;
}

export function SidebarWithHeader({
    children,
}: {
    children: ReactNode;
}) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isUserStaking } = useAuth();
    const [entries, setEntries] = React.useState([
        { name: 'Home', icon: FiHome, path: '/home' },
        { name: 'Documentation', icon: IoDocument, path: 'https://docs.dotmoovs.ai' },
        { name: 'API Key', icon: FaLock, path: "/api-key", withDivider: true },
    ]);

    const addSettingsEntry = (isStaking: boolean) => {
        console.log("Staking: ", isStaking);
        console.log("Entries: ", entries);
        if (!isStaking) {
            console.log("Adding settings entry");
            setEntries([
                ...entries,
                { name: 'Settings', icon: FiSettings, path: '/settings' }
            ]);
        }
    }

    useEffect(() => {
        isUserStaking(addSettingsEntry)
    }, []);

    return (
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
            <SidebarContent
                items={entries}
                onClose={() => onClose}
                display={{ base: 'none', md: 'block' }}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent items={entries} onClose={onClose} />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav onOpen={onOpen} />
            <Box ml={{ base: 0, md: 60 }} p="4">
                {children}
            </Box>
        </Box>
    );
}

interface SidebarProps extends BoxProps {
    items: Array<LinkItemProps>
    onClose: () => void;
}

const SidebarContent = ({ items, onClose, ...rest }: SidebarProps) => {
    return (
        <Box
            transition="3s ease"
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <Logo />
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            {items.map((link) => {
                return <>
                    {link.withDivider && <Divider />}
                    <NavItem key={link.name} icon={link.icon} onClick={
                        () => {
                            if (link.path.startsWith('http') || link.path.startsWith('https')) {
                                window.open(link.path, '_blank');
                            }
                            else {
                                window.location.href = link.path;
                            }
                        }
                    }>
                        {link.name}
                    </NavItem>
                </>
            })}
        </Box>
    );
};

interface NavItemProps extends FlexProps {
    icon: IconType;
    children: ReactText;
}
const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
    return (
        <Link href="#" style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: '#002BFF',
                    color: 'white',
                }}
                {...rest}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Link>
    );
};

interface MobileProps extends FlexProps {
    onOpen: () => void;
}
export const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
    const { logout, isUserStaking } = useAuth();
    const [userStaking, setUserStaking] = React.useState(false);

    const logoutAndRedirect = () => {
        logout();
        window.location.href = "/";
    }

    useEffect(() => {
        isUserStaking(setUserStaking);
    }, []);

    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
            {...rest}>
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />

            <HStack spacing={{ base: '0', md: '6' }}>
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: 'none' }}>
                            <HStack>
                                <Avatar
                                    size={'sm'}
                                    name={firebase_auth.currentUser?.displayName ? firebase_auth.currentUser?.displayName : 'User'}
                                />
                                <VStack
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2">
                                    <Text fontSize="sm">
                                        {firebase_auth.currentUser?.displayName}
                                    </Text>
                                </VStack>
                                <Box display={{ base: 'none', md: 'flex' }}>
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList
                            bg={useColorModeValue('white', 'gray.900')}
                            borderColor={useColorModeValue('gray.200', 'gray.700')}>
                            {
                                !userStaking &&
                                <>
                                    <MenuItem
                                        onClick={() => {
                                            window.location.href = "/settings";
                                        }}
                                    >Settings</MenuItem>
                                    <MenuDivider />
                                </>
                            }
                            <MenuItem
                                onClick={logoutAndRedirect}
                            >
                                Sign out
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    );
};