import {
    Button,
    Center,
    Stack,
    Text
} from '@chakra-ui/react';
import { FaGithub } from "react-icons/fa";
import { FcGoogle } from 'react-icons/fc';


interface SocialButtonGroupProps {
    githubLogin: () => void;
    googleLogin: () => void;
}

interface SocialButtonProps {
    icon: any;
    text: string;
    onClick: () => void;
}

function SocialButton({ icon, text, onClick }: SocialButtonProps) {
    return (
        <Button
            w={'full'}
            maxW={'md'}
            variant={'outline'}
            leftIcon={icon}
            onClick={onClick}
        >
            <Center>
                <Text>{text}</Text>
            </Center>
        </Button>
    );
}


export function SocialButtonGroup(props: SocialButtonGroupProps) {
    return (
        <Stack
            direction={{ base: "column", md: "row" }}
        >
            <SocialButton
                icon={<FcGoogle />}
                text="Google"
                onClick={props.googleLogin}
            />
            <SocialButton
                icon={<FaGithub />}
                text="GitHub"
                onClick={props.githubLogin}
            />
        </Stack>
    );
}