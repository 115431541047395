import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { SidebarWithHeader } from './Drawer';
import { firebase_auth } from '../auth/JWTAuthContext';
import { browserLocalPersistence } from '@firebase/auth';
import useAuth from '../hooks/useAuth';

const AuthGuard = ({ requiresAuth }) => {
  const [isAuthenticatedState, setIsAuthenticatedState] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    firebase_auth.setPersistence(browserLocalPersistence)
      .then(() => {
        setIsAuthenticatedState(isAuthenticated());
        setIsAuthChecked(true);
      })
      .catch((error) => {
        console.error("Error setting Firebase persistence:", error);
        setIsAuthChecked(true);
      })
  }, []);

  if (!isAuthChecked) {
    return null;
  }

  if (!requiresAuth) {
    return <Outlet />
  }

  if (!isAuthenticatedState) {
    return <Navigate to="/" />
  }

  return (
    <SidebarWithHeader
      children={<Outlet />}
    />
  )
};

export default AuthGuard;
