import { ChakraProvider, Code } from "@chakra-ui/react"
import { Highlight, themes } from "prism-react-renderer"

const codeSample = `
# Load pose estimator
pose_estimator = PoseFlow()

# Initialize video
video = QVideo("surfing.mp4")

# Estimate poses
pose_data = await pose_estimator(video)

# Output results
await pose_data.view(video, "surfing_ai.mp4")
`

export const SampleCode = ({ }) => {
    return (
        <ChakraProvider resetCSS>
            <Highlight
                language="python"
                code={codeSample}
                theme={themes.okaidia}
            >
                {({ style, tokens, getLineProps, getTokenProps }) => (
                    <Code
                        padding={2}
                        rounded="md"
                        display="block"
                        whiteSpace="pre"
                        backgroundColor={style.backgroundColor}
                        overflow="auto"
                    >
                        {tokens.map((line, i) => {
                            let lineProps = getLineProps({ line })

                            lineProps["style"] = { ...lineProps["style"], "text-align": "left" }

                            console.log(lineProps["style"])

                            return <div key={i} {...lineProps}>
                                {
                                    line.map((token, key) => (
                                        <span key={key} {...getTokenProps({ token })} />
                                    ))
                                }
                            </div>
                        })}
                    </Code>
                )}
            </Highlight>
        </ChakraProvider >
    )
}