import React from "react";
import { createRoot } from "react-dom/client";
import {App} from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
// import "./signUp.css";

// Define your theme for Chakra UI
const chakraTheme = extendTheme({
  styles: { global: { img: { maxWidth: "unset" } } },
});

// Create your Emotion cache configuration
const emotionCache = createCache({
  key: "emotion-cache",
  prepend: true,
});

// Get the container element
const container = document.getElementById("root");

// Check if container is not null
if (container) {
  const root = createRoot(container); // Create a root.
  
  // Render your app wrapped with providers
  root.render(
      <BrowserRouter>
        <CacheProvider value={emotionCache}>
          <ChakraProvider theme={chakraTheme}>
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </ChakraProvider>
        </CacheProvider>
      </BrowserRouter>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

