'use client'

import {
    Button,
    Flex,
    Heading,
    Image,
    Stack,
    Text,
    useBreakpointValue,
    Box, chakra, Icon
} from '@chakra-ui/react'

interface HeroScreenProps {
    userLoggedIn: boolean
}

export function HeroScreen(
    { userLoggedIn }: HeroScreenProps
) {
    return <Box px={8} py={24} mx="auto" id="home">
        <Box
            w={{
                base: "full",
                md: 11 / 12,
                xl: 9 / 12,
            }}
            mx="auto"
            textAlign={{
                base: "left",
                md: "center",
            }}
        >
            <chakra.h1
                mb={6}
                fontSize={{
                    base: "4xl",
                    md: "6xl",
                }}
                fontWeight="bold"
                lineHeight="none"
                letterSpacing={{
                    base: "normal",
                    md: "tight",
                }}
                color="gray.900"
                _dark={{
                    color: "gray.100",
                }}
            >
                Transform Your Application with Cutting-Edge{" "}
                <Text
                    display={{
                        base: "block",
                        lg: "inline",
                    }}
                    w="full"
                    bgClip="text"
                    bgGradient="linear(to-r, #E5E9FF,#002BFF)"
                    fontWeight="extrabold"
                >
                    Vision AI
                </Text>
            </chakra.h1>
            <chakra.p
                px={{
                    base: 0,
                    lg: 24,
                }}
                mb={6}
                fontSize={{
                    base: "lg",
                    md: "xl",
                }}
                color="gray.600"
                _dark={{
                    color: "gray.300",
                }}
            >
                Empower your software with advanced object detection, human pose estimation, and real-time multi-object tracking to revolutionize how you interact with the physical world.
            </chakra.p>
            <Stack
                direction={{
                    base: "column",
                    sm: "row",
                }}
                mb={{
                    base: 4,
                    md: 8,
                }}
                spacing={2}
                justifyContent={{
                    sm: "left",
                    md: "center",
                }}
            >
                <Button
                    as="a"
                    variant="solid"
                    backgroundColor="#002BFF"
                    textColor="#F3F3F3"
                    display="inline-flex"
                    alignItems="center"
                    justifyContent="center"
                    w={{
                        base: "full",
                        sm: "auto",
                    }}
                    mb={{
                        base: 2,
                        sm: 0,
                    }}
                    size="lg"
                    cursor="pointer"
                    onClick={() => {
                        if (userLoggedIn) {
                            window.location.href = '/home'
                        } else {
                            window.location.href = `/signup?form=trial`
                        }
                    }}
                >
                    {
                        userLoggedIn ? "Go To Console" : "Start Free Trial"
                    }
                    <Icon boxSize={4} ml={1} viewBox="0 0 20 20" fill="currentColor">
                        <path
                            fillRule="evenodd"
                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </Icon>
                </Button>
            </Stack>
        </Box>
        <Box
            w={{
                base: "full",
                md: 10 / 12,
            }}
            sx={{
                borderRadius: "1%"
            }}
            autoPlay
            loop
            muted
            as="video"
            src="https://d3l14jro8mrgku.cloudfront.net/dotmoovs-business/dotmoovs-business-video.mp4"
            mx="auto"
            mt={20}
            textAlign="center"
        />
    </Box>
}