'use client'

import {
  Box,
  Button,
  HStack,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'
import { FaCheckCircle } from 'react-icons/fa'

interface Props {
  children: React.ReactNode
}

function PriceWrapper(props: Props) {
  const { children } = props

  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
      borderRadius={'xl'}>
      {children}
    </Box>
  )
}

interface PricingProps {
  currentUsagePlan?: string;
  ignoreTrialMessage?: boolean;
  ignoreHeader?: boolean;
}

export function TieredPricing(props: PricingProps) {
  const { currentUsagePlan, ignoreTrialMessage, ignoreHeader } = props;

  const startTrial = (form: string) => {
    // open new tab to stripe checkout
    window.location.href = `/signup?form=${form}`
  }

  const upgradePlan = (form: string) => {
    // open new tab to stripe checkout
    window.location.href = `/upgrade?form=${form}`
  }

  const cancelPlan = () => {
    // open new tab to stripe checkout
    window.location.href = `/cancel`
  }

  const trialOrUpgrade = ignoreTrialMessage ? upgradePlan : startTrial;
  const message = ignoreTrialMessage ? 'Upgrade' : 'Start trial';

  return (
    <Box py={12} id="pricing">
      {
        ignoreHeader ? null : (
          <VStack spacing={2} textAlign="center">

            <Heading as="h1" fontSize="4xl">
              Plans that fit your needs
            </Heading>
            {
              !ignoreTrialMessage && (
                <Text fontSize="lg" color={'gray.500'}>
                  Start with 7-day free trial. Cancel at anytime.
                </Text>
              )
            }
          </VStack>
        )
      }
      <Stack
        direction={{ base: 'column', md: 'row' }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}>
        <PriceWrapper>
          <Box py={4} px={12}>
            {
              currentUsagePlan === 'basic' &&
              <Text fontWeight="3xl" color="#B2BFFF">
                CURRENT
              </Text>
            }
            <Text fontWeight="500" fontSize="2xl">
              Basic
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600">
                $
              </Text>
              <Text fontSize="5xl" fontWeight="900">
                99
              </Text>
              <Text fontSize="3xl" color="gray.500">
                /month
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue('gray.50', 'gray.700')}
            py={4}
            borderBottomRadius={'xl'}>
            <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Ideal for small projects or startups.
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Up to 10,000 requests per month.
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Basic support.
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              <Button w="full" borderColor="#002BFF"
                color={
                  currentUsagePlan === 'basic' ? '#FF0000' : '#002BFF'
                }
                variant={currentUsagePlan === 'basic' ? 'solid' : 'outline'}
                onClick={() => {
                  if (currentUsagePlan === 'basic') {
                    cancelPlan()
                  }
                  else {
                    trialOrUpgrade('trial')
                  }
                }}
              >
                {currentUsagePlan === 'basic' ? 'Cancel' : message}
              </Button>
            </Box>
          </VStack>
        </PriceWrapper>

        <PriceWrapper>
          <Box position="relative">
            <Box
              position="absolute"
              top="-16px"
              left="50%"
              style={{ transform: 'translate(-50%)' }}>
              <Text
                textTransform="uppercase"
                bg={useColorModeValue('#B2BFFF', '#B2BFFF')}
                px={3}
                py={1}
                color={useColorModeValue('#F3F3F3', '#F3F3F3')}
                fontSize="sm"
                fontWeight="600"
                rounded="xl">
                Most Popular
              </Text>
            </Box>
            <Box py={4} px={12}>
              {
                currentUsagePlan === 'professional' &&
                <Text fontWeight="3xl" color="#B2BFFF">
                  CURRENT
                </Text>
              }
              <Text fontWeight="500" fontSize="2xl">
                Professional
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="3xl" fontWeight="600">
                  $
                </Text>
                <Text fontSize="5xl" fontWeight="900">
                  299
                </Text>
                <Text fontSize="3xl" color="gray.500">
                  /month
                </Text>
              </HStack>
            </Box>
            <VStack
              bg={useColorModeValue('gray.50', 'gray.700')}
              py={4}
              borderBottomRadius={'xl'}>
              <List spacing={3} textAlign="start" px={12}>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Best for mid-sized businesses.
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Up to 100,000 requests per month.
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Priority support.
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                <Button w="full" borderColor="#002BFF"
                  color={
                    currentUsagePlan === 'professional' ? '#FF0000' : '#002BFF'
                  }
                  variant={currentUsagePlan === 'professional' ? 'solid' : 'outline'}
                  onClick={() => {
                    if (currentUsagePlan === 'professional') {
                      cancelPlan()
                    }
                    else {
                      trialOrUpgrade('professional')
                    }
                  }}
                >
                  {currentUsagePlan === 'professional' ? 'Cancel' : message}
                </Button>
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>
        <PriceWrapper>
          <Box py={4} px={12}>
            {
              currentUsagePlan === 'enterprise' &&
              <Text fontWeight="3xl" color="#B2BFFF">
                CURRENT
              </Text>
            }
            <Text fontWeight="500" fontSize="2xl">
              Enterprise
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="1xl" fontWeight="600">
                $MOOV
              </Text>
              <Text fontSize="5xl" fontWeight="900">
                450K
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue('gray.50', 'gray.700')}
            py={4}
            borderBottomRadius={'xl'}>
            <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Designed for large-scale needs.
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Unlimited requests per month.
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Priority support.
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              <Button w="full"
                borderColor="#002BFF"
                color={
                  currentUsagePlan === 'enterprise' ? '#F3F3F3' : '#F3F3F3'
                }
                bg="#002BFF"
                _hover={{
                  color: '#002BFF',
                  bg: '#F3F3F3'
                }}
                variant={currentUsagePlan === 'enterprise' ? 'solid' : 'outline'}
                onClick={
                  () => {
                    if (currentUsagePlan === 'enterprise') {
                      cancelPlan()
                    }
                    else {
                      // Forward to the staking page in a new window
                      // window.location.href = `https://app.moovtoken.com/staking/tier3`
                      window.open(`https://app.moovtoken.com/staking/tier3`, '_blank')
                    }
                  }
                }
              >
                {currentUsagePlan === 'enterprise' ? 'Cancel' : "Start staking"}
              </Button>
            </Box>
          </VStack>
        </PriceWrapper>
      </Stack>
    </Box>
  )
}