import { ChangeEvent, useEffect, useRef, useState } from "react";

import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Link as ChakraLink,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { OrDivider } from "../components/OrDivider";
import { SocialButtonGroup } from "../components/SocialButtonGroup";
import useAuth from "../hooks/useAuth";

interface LoginUserProfile {
  email: string;
  password: string;
}

function SignIn() {
  const { isAuthenticated } = useAuth();
  const { socialLogin, manualLogin } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const profile = useRef<LoginUserProfile>({
    email: "",
    password: "",
  });

  const setEmail = (event: ChangeEvent<HTMLInputElement>) => {
    profile.current.email = event.target.value;
  }

  const setPassword = (event: ChangeEvent<HTMLInputElement>) => {
    profile.current.password = event.target.value;
  }

  const forwardHome = () => {
    window.location.href = "/home";
  }

  useEffect(() => {
    // check if the user is already logged in
    console.log("Checking if user is already logged in", isAuthenticated())
    if (isAuthenticated()) {
      console.log("User is already logged in");
      window.location.href = "/home";
    } else {
      console.log("User is not logged in");
    }
  }, []);

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg="#F3F3F3">
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'} textAlign={'center'}>
            Sign in to Dotmoovs-Business

          </Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            Welcome back! Please sign in to continue
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <SocialButtonGroup
              githubLogin={() => socialLogin({type:"github"}, forwardHome)}
              googleLogin={() => socialLogin({type:"google"}, forwardHome)}
            />
            <OrDivider />
            <FormControl id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input type="email" onChange={setEmail} />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input type={showPassword ? 'text' : 'password'} onChange={setPassword} />
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }>
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Stack spacing={10} pt={2}>
              <Button
                loadingText="Submitting"
                size="lg"
                bg={'#002BFF'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
                onClick={() => {
                  manualLogin(profile.current, forwardHome);
                  // manualSignUp(profile.current, forwardHome);
                }}
              >
                Continue
              </Button>
            </Stack>
            <Stack pt={6}>
              <Text align={'center'}>
                Don’t have an account? <ChakraLink color={'#002BFF'} as={ReactRouterLink} to="/signup">Sign up</ChakraLink>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}

export default SignIn;