import { Navigate, Route, Router, Routes } from 'react-router-dom';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import HomePage from './pages/HomePage';
import { LandingPage } from './pages/LandingPage';
import { AuthProvider } from './auth/JWTAuthContext';
import AuthGuard from './components/AuthGuard';
import APIKeyScreen from './pages/APIKeyScreen';
import Settings from './pages/Settings';
import { Suspense, lazy } from 'react';


export const App = () => (
  <Routes>
    <Route element={<AuthProvider children={<AuthGuard requiresAuth={false} />} />} >
      <Route path="/" element={<LandingPage />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/signin" element={<SignIn />} />
    </Route>
    <Route element={<AuthProvider children={<AuthGuard requiresAuth={true} />} />}>
      <Route path="/home" element={<HomePage />} />
      <Route path="/api-key" element={<APIKeyScreen />} />
      <Route path="/settings" element={<Settings />} />
    </Route>
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
)
