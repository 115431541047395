import { createContext, useState } from 'react'
import { initializeApp } from '@firebase/app';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, getAuth, browserLocalPersistence, signInWithPopup, GoogleAuthProvider, GithubAuthProvider } from "@firebase/auth";
import { Outlet, useNavigate } from 'react-router-dom';

export const STRIPE_URL_BASIC = "https://buy.stripe.com/dR68zMcQ01F8auQ4gr"
export const STRIPE_URL_PROFESSIONAL = "https://buy.stripe.com/6oEaHU2bmdnQ1Yk5kw"

// export const STRIPE_URL_BASIC = "https://buy.stripe.com/test_8wMaIc0MJdq07u09AP"
// export const STRIPE_URL_PROFESSIONAL = "https://buy.stripe.com/test_3cs17CgLHadO01y7sI"


const AuthContext = createContext({
    method: 'JWT',
    isAuthenticated: () => { return false; },
    logout: () => { },
    manualSignUp: (profile, onSuccess, onError) => { },
    manualLogin: (profile, onSuccess, onError) => { },
    socialLogin: (type, onSuccess, onError) => { }, // Social login can also serve as signup
    isUserStaking: (onStaking) => { }
})

// Setup firebase authentication mechanism
const firebaseConfig = {
    apiKey: "AIzaSyA6T1VN6gO1J5d4JEz4a_28b_fqe4sbCuo",
    authDomain: "dotmoovs-business-421517.firebaseapp.com",
    projectId: "dotmoovs-business-421517",
    storageBucket: "dotmoovs-business-421517.appspot.com",
    messagingSenderId: "96220176330",
    appId: "1:96220176330:web:a74b2d0d42396b594b3933",
    measurementId: "G-ZW4X9MPQRP"
};

export const firebase = initializeApp(firebaseConfig);
export const firebase_auth = getAuth(firebase);

export const isAuthenticated = () => {
    return firebase_auth.currentUser !== null
}

export const isUserStaking = async (onStaking) => {
    if (firebase_auth.currentUser) {
        const auth = getAuth();
        const user = auth.currentUser;
        const email = user?.providerData.find(profile => profile.email)?.email || null;

        const response = await fetch("https://prod-dotmoovs-business-api-picjklnwjq-ew.a.run.app/is_staking", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email }),
        })

        if (response.ok) {
            const data = await response.json();
            onStaking(data.isStaking);
        }
    }
}


export async function getIdToken() {
    return await firebase_auth.currentUser?.getIdToken()
}

function checkFormString(str) {
    // check if string is empty
    if (!str) {
        return false;
    }
    // check if > 0
    if (str.length <= 0) {
        return false;
    }
    return true;
}

export function forwardStripe(email, formName) {
    console.log("Forward to payment with ", formName)
    // Check the plan and set the Stripe link accordingly
    let stripeLink = undefined;


    switch (formName) {
        case "trial":
            stripeLink = `${STRIPE_URL_BASIC}?prefilled_email=${email}`;
            break;
        case "basic":
            stripeLink = `${STRIPE_URL_BASIC}?prefilled_email=${email}`;
            break;
        case "professional":
            stripeLink = `${STRIPE_URL_PROFESSIONAL}?prefilled_email=${email}`;
            break;
        default:
            stripeLink = undefined
            break;
    }
    if (stripeLink) {
        // Redirect to the Stripe payment link
        window.location.href = stripeLink;
    }
    return true;
}


function checkFormProfile(profile) {
    // check if profile contains the following
    /*
        firstName: string;
        lastName: string;
        email: string;
        password: string;
    */
    console.log("profile -> ", profile);
    for (const key in profile) {
        if (!checkFormString(profile[key])) {
            return false;
        }
    }
    return true;
}


export const AuthProvider = ({ children }) => {
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const providers = {
        google: GoogleAuthProvider,
        github: GithubAuthProvider
    }

    const socialLogin = async (form, onSuccess, onError) => {
        // Check if valid
        const type = form.type;
        // check if formName otherwise ""
        const formName = form.formName ? form.formName : "";
        if (!providers[type]) {
            console.error("Provider not supported");
            return;
        }
        console.log("Social login with ", type);
        console.log("Social login with formName ", formName);
        // A popup window will be opened and the user can sign in with their Google account.
        const provider = new providers[type]();
        provider.addScope('email');
        provider.addScope('profile');

        console.log('Requested scopes:', provider.scopes);
        signInWithPopup(firebase_auth, provider)
            .then((result) => {
                // The signed-in user info.
                const user = result.user;
                // Reload the user to ensure all properties are populated
                const email = user?.providerData.find(profile => profile.email)?.email || null;
                if (formName != "") {
                    forwardStripe(email, formName);
                }
                /*
                user.reload().then(() => {
                    const reloadedUser = firebase_auth.currentUser;
                    console.log('Reloaded user email:', reloadedUser.email);

                    if (onSuccess) {
                        onSuccess(reloadedUser);
                    }
                }).catch((reloadError) => {
                    console.error('Error reloading user:', reloadError);
                    if (onError) {
                        onError(reloadError);
                    }
                });
                */
            }).catch((error) => {
                console.error(error);
                if (onError) {
                    onError(error);
                }
            });
    }

    const logout = async (onSuccess, onError) => {
        firebase_auth.signOut().then(() => {
            if (onSuccess) {
                onSuccess();
            }
        }).catch((error) => {
            console.error(error);

            if (onError) {
                onError(error);
            }

            // Step 2: User's email already exists.
            if (error.code === "auth/account-exists-with-different-credential") {
                // sign-in method.
                setError("Account exists with a different credential. Please sign in using that provider.");
                alert("Account exists with a different credential. Please sign in using Google");
            } else {
                setError("Provider not supported");
                return;
            }
        });
    }

    const manualSignUp = (profile, onSuccess, onError) => {
        console.log("manualSignUp -> ", profile);
        // check if lastName is provided and > 0
        const formCheck = checkFormProfile(profile);
        if (!formCheck) {
            // sign up with email and password
            alert("Please provide all fields");
        }
        console.log("Email sign up, profile validated -> ", profile);
        // sign up with email and password
        createUserWithEmailAndPassword(firebase_auth, profile.email, profile.password).then((result) => {
            console.log("User created -> ", result.user);
            // console.log("HERE ", onSuccess);
            //onSuccess(result.user);
            forwardStripe(profile.email, profile.formName);
        }).catch((error) => {
            if (error.code === "auth/weak-password") {
                // alter the firebase error message
                setError("Password is too weak");
                console.error("Password is too weak");
                alert(error.message);
                return;
            }
            if (error.code === "auth/invalid-email") {
                // alter the firebase error message
                setError("Invalid email");
                console.error("Invalid email");
                alert("Invalid email. Please provide a valid email address");
                return;
            }
            if (error.code === "auth/email-already-in-use") {
                // alter the firebase error message
                setError("Email already in use");
                console.error("Email already in use");
                alert("Email already in use. Please sign in or use another email address");
                return;
            }
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error("Failed to sign up with email and password: ", errorMessage, errorCode);
            setError("Provider not supported");
            onError(error);
            return;
        });
    }

    // create custo, interface witj supported types
    const manualLogin = (profile, onSuccess, onError) => {
        // check if lastName is provided and > 0
        const formCheck = checkFormProfile(profile);
        if (!formCheck) {
            // sign up with email and password
            alert("Please provide all fields");
        }
        console.log("Email sign up, profile validated -> ", profile);
        // sign up with email and password
        signInWithEmailAndPassword(firebase_auth, profile.email, profile.password).then((userCredential) => {
            if (onSuccess) {
                onSuccess(userCredential.user);
            }
        }).catch((error) => {
            if (error.code === "auth/invalid-email") {
                // alter the firebase error message
                setError("Invalid email");
                console.error("Invalid email");
                alert("Invalid email. Please provide a valid email address");
                return;
            }
            if (error.code == 'auth/invalid-credential') {
                // alter the firebase error message
                setError("Invalid credential");
                console.error("Invalid credential");
                alert("Invalid credential. Please provide a valid email address and password");
                return;
            }

            const errorCode = error.code;
            const errorMessage = error.message;
            console.error("Failed to sign in with email and password: ", errorMessage, errorCode);
            setError("Manual Sign in failed");

            if (onError) {
                onError(error);
            }

            return;
        });
    }

    return (
        <AuthContext.Provider
            value={{
                method: 'JWT',
                isAuthenticated,
                logout,
                manualSignUp,
                manualLogin,
                socialLogin,
                isUserStaking
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext